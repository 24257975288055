import { Box, Card, Stack, Typography } from '@mui/material';
import { BackButton } from 'components/index';
import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { FC, useMemo, useState } from 'react';
import CollapsedCard from 'pages/Lesson/Homework/SecondPartAnswer/CollapsedCard';
import { ExerciseStatistic, QuestionElementLkDto, useCreateAnswerFeedbackMutation } from '@generated/graphql';
import { IExerciseOutletContext } from 'pages/Lesson/Lecture/Lecture';
import { useForm } from 'react-hook-form';
import UserAnswerForm from 'pages/Lesson/Homework/SecondPartAnswer/UserAnswerForm';
import { LoadingButton } from '@mui/lab';

/*
eslint-disable
*/

const SecondPartAnswer: FC<{ currentElement: QuestionElementLkDto; userStatistic: ExerciseStatistic | null | undefined }> = ({
  currentElement,
  userStatistic,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { subject } = useOutletContext<IExerciseOutletContext>();
  const [showForm, setShowForm] = useState<boolean>();
  const form = useForm();
  const { handleSubmit } = form;

  const [createAnswerFeedback, { loading }] = useCreateAnswerFeedbackMutation();

  const onSubmit = (formData: any) => {
    createAnswerFeedback({
      variables: {
        input: {
          answerId: currentElement?.currentUserAnswers?.[0].id!,
          files: formData.files?.length ? formData.files : undefined,
          message: formData.message,
        },
      },
    }).then(() => setShowForm(false));
  };

  const handleClick = () => {
    if (showForm) handleSubmit(onSubmit)();
    else setShowForm(true);
  };

  const history = useMemo(
    () => currentElement?.currentUserAnswers?.[0]?.history?.filter((item) => item.message),
    [JSON.stringify(currentElement?.currentUserAnswers?.[0]?.history)],
  );

  const scoreB: number = userStatistic?.scoreB || 0;
  const maxScoreB: number = userStatistic?.maxScoreB || 0;
  return (
    <Stack spacing={2} flex={1} display='flex'>
      <Card component={Stack}>
        <BackButton navigate={() => navigate(`/${generatePath(ROUTES.BLOCK, params)}`)} text='Назад к урокам' sx={{ mb: 2.5 }} />
        <Stack spacing={1} direction='row' justifyContent='space-between' mb={2.5}>
          <Typography variant='h2'>Оценка</Typography>
          <Typography variant='h3'>{`${scoreB}/${maxScoreB}`}</Typography>
        </Stack>
        <Stack spacing={2.5}>
          <CollapsedCard currentElement={currentElement} />
          {history?.length ? history.map((historyItem) => <CollapsedCard historyItem={historyItem} />) : null}
          {showForm && <UserAnswerForm form={form} hideForm={() => setShowForm(false)} showForm={showForm} />}
        </Stack>
      </Card>
      <Box flex={1} component='div' />
      <LoadingButton loading={loading} variant='contained' color='secondary' subject={subject} onClick={handleClick}>
        {showForm ? 'Отправить' : 'Написать куратору'}
      </LoadingButton>
    </Stack>
  );
};

export default SecondPartAnswer;
