import { Outlet } from 'react-router-dom';
import Container from 'layouts/Container';
import { CourseSubject } from '@generated/graphql';
import { Dispatch, SetStateAction, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import getBackground from 'helpers/main';

type SelectedCoursesByGrade = Record<string, string[]>;

export interface OnboardingOutletProps {
  subject: CourseSubject;
  selectedCoursesByGrade: SelectedCoursesByGrade;
  setSelectedCoursesByGrade: Dispatch<SetStateAction<SelectedCoursesByGrade>>;
}

const OnboardingOutlet = () => {
  const theme = useTheme();
  const [selectedCoursesByGrade, setSelectedCoursesByGrade] = useState<SelectedCoursesByGrade>({});

  const background = getBackground(theme, 'defaultColor', 'background');
  return (
    <Box
      component='div'
      sx={{
        background,
      }}
    >
      <Container maxWidth='md'>
        <Outlet context={{ subject: 'defaultColor', selectedCoursesByGrade, setSelectedCoursesByGrade }} />
      </Container>
    </Box>
  );
};

export default OnboardingOutlet;
