import React, { useEffect, useRef, useState } from 'react';
import KinescopePlayer from '@kinescope/react-kinescope-player';
import { Box, Link, Stack } from '@mui/material';
import { useCreateSingleProgressMutation } from '@generated/graphql';
import useTelegram from 'hooks/useTelegram';
import { useOutletContext } from 'react-router-dom';
import { IExerciseOutletContext } from 'pages/Lesson/Lecture/Lecture';

export type VideoProps = {
  kinescopeId: string;
  id: string;
};

const Video = ({ kinescopeId, id }: VideoProps) => {
  const { subject, refetchLesson } = useOutletContext<IExerciseOutletContext>();
  const videoRef = useRef<any>();

  const [createProgress] = useCreateSingleProgressMutation();

  const onCreateProgress = () => {
    createProgress({
      variables: {
        elementId: id,
      },
    })
      .then(() => refetchLesson?.())
      .catch(console.error);
  };

  const { tg } = useTelegram();

  const isTgMobile = tg.platform === 'android' || tg.platform === 'ios';

  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const isLandscape = windowSize[0] > windowSize[1];

  return (
    <Stack spacing={1}>
      <Box
        component='div'
        sx={{
          width: isTgMobile && isLandscape ? 'auto' : '100%',
          position: 'relative',
          paddingTop: isTgMobile && isLandscape ? '262px' : '56.25%',
          '& iframe': { position: 'absolute', top: 0, left: 0 },
        }}
      >
        <KinescopePlayer ref={videoRef} videoId={kinescopeId} onEnded={onCreateProgress} />
      </Box>
      {tg.platform !== 'unknown' && (
        <Link target='_blank' href={`https://kinescope.io/embed/${kinescopeId}`} sx={(theme) => ({ color: theme.palette[subject].main })}>
          Открыть в полноэкранном режиме
        </Link>
      )}
    </Stack>
  );
};

export default Video;
