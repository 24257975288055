import { Box, useTheme } from '@mui/material';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { CourseSubject, useGetCourseThemeQuery } from '@generated/graphql';
import getBackground from 'helpers/main';
import { CourseSubjectColor } from 'types/common';
import Container from './Container';

type CourseLayoutParams = {
  courseId: string;
  blockId?: string;
  lessonId?: string;
  exerciseId?: string;
  elementId?: string;
  subject?: CourseSubject;
};

const CourseLayout = () => {
  const theme = useTheme();
  const [subject, setSubject] = useState<CourseSubjectColor>('defaultColor');
  const params = useParams<CourseLayoutParams>();
  const { blockId, subject: subjectParam } = params;
  const [searchParams] = useSearchParams();
  const courseId = params.courseId || searchParams.get('courseId');

  const { data, loading } = useGetCourseThemeQuery({
    variables: {
      courseId: courseId!,
    },
    skip: !courseId,
  });

  const courseSubject = data?.courseCard.course.subject || subjectParam;
  useEffect(() => {
    if (courseSubject) setSubject(courseSubject);
  }, [courseSubject]);

  const background = getBackground(theme, courseSubject, blockId ? 'background' : 'mainBackground');

  return (
    <Box
      component='div'
      sx={{
        background,
      }}
    >
      <Container maxWidth='md'>
        <Outlet context={{ subject, setSubject, themeLoading: loading }} />
      </Container>
    </Box>
  );
};

export default CourseLayout;
