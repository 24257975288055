const ROUTES = {
  PAYMENT: 'payment',
  SUCCESS_PAYMENT: 'success-payment',
  COURSE: 'course/:courseId',
  BLOCK: 'course/:courseId/:blockId',
  LESSON: 'course/:courseId/:blockId/:lessonId',
  EXERCISE: 'course/:courseId/:blockId/:lessonId/:exerciseId',
  ELEMENT: 'course/:courseId/:blockId/:lessonId/:exerciseId/:elementId',
  QUIZ: 'quiz',
  LANDING: '/',
  ONBOARDING: 'onboarding',
  SELECT_GRADE: 'select-grade',
  SIGNUP: 'signup',
};

export default ROUTES;
