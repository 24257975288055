import { Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const BackButton = ({ text, navigate, sx }: { text: string; navigate: () => any; sx?: object }) => (
  <Button variant='text' onClick={navigate} sx={{ p: 0, width: 'fit-content', ...sx }}>
    <ArrowBack sx={{ color: 'base.400', mr: 1 }} />
    {text}
  </Button>
);

export default BackButton;

BackButton.defaultProps = {
  sx: undefined,
};
