import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useThemeParams } from '@vkruglikov/react-telegram-web-app';
import { RouterProvider } from 'react-router-dom';
import { ModalProvider } from 'context/modalContext';
import { SubjectProvider } from 'context/subjectContext';
import { AuthProvider } from 'context/authContext';
import { ToastProvider } from 'context/toastContext';
import useTelegram from 'hooks/useTelegram';
import client from './config/apolloConfig';
import router from './router';
import createAppTheme from './theme';

const App = () => {
  const [colorScheme] = useThemeParams();
  const theme = createAppTheme({ theme: colorScheme });
  const { tg } = useTelegram();
  useEffect(() => {
    tg.enableClosingConfirmation();
  }, []);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <SubjectProvider>
            <ToastProvider>
              <ModalProvider>
                <CssBaseline />
                <RouterProvider router={router} />
              </ModalProvider>
            </ToastProvider>
          </SubjectProvider>
        </AuthProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
