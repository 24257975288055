import { useController, UseControllerProps } from 'react-hook-form';
import { TextField, TextFieldProps, InputAdornment, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import * as React from 'react';

type TextFieldControlProps = {
  name: string;
  endIcon?: ReactNode;
  handleChange?: (value: string, onChange: any) => any;
  isError?: boolean;
  sx?: SxProps;
};

const TextFieldControl: FC<Omit<TextFieldProps, 'label'> & TextFieldControlProps & UseControllerProps> = ({
  name,
  placeholder,
  variant,
  size,
  rules,
  handleChange,
  ...props
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ defaultValue: '', name, rules });

  return (
    <TextField
      {...props}
      {...field}
      onChange={(e) => {
        const { value } = e.target;
        if (handleChange) handleChange(value, field.onChange);
        else field.onChange(value);
      }}
      disabled={props.disabled}
      size={size}
      error={!!error || props.isError}
      fullWidth
      placeholder={placeholder}
      variant={variant}
      InputProps={{
        endAdornment: props.endIcon && <InputAdornment position='end'>{props.endIcon}</InputAdornment>,
      }}
    />
  );
};

export default TextFieldControl;

TextFieldControl.defaultProps = {
  endIcon: undefined,
  handleChange: undefined,
  isError: false,
  sx: undefined,
};
