/* eslint-disable */
import { ApolloClient, ApolloLink, from, InMemoryCache, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import cookies from 'js-cookie';
import { webApp } from 'hooks/useTelegram';
import { getItem, removeItem, setItem } from 'helpers/cloudStorage';
import { generateBase64 } from 'helpers/formaters';
const isTelegram = webApp.platform !== 'unknown';
const graphqlLink = `${process.env.REACT_APP_API_URL}/graphql`;

const httpLink = createUploadLink({
  uri: graphqlLink,
  fetchOptions: {
    credentials: 'include',
  },
  credentials: 'include',
  headers: { 'Apollo-Require-Preflight': 'true' },
}) as unknown as ApolloLink;

const getToken = async (key: string) => {
  let token;
  if (isTelegram) {
    token = await getItem(key);
  } else {
    token = cookies.get(key);
  }
  return token;
};

const setToken = async (key: string, value: string) => {
  if (isTelegram) {
    await setItem(key, value);
  } else {
    cookies.set(key, value);
  }
};

const removeToken = async (key: string) => {
  if (isTelegram) {
    await removeItem(key);
  } else {
    cookies.remove(key);
  }
};

// @ts-ignore
const authMiddleware = setContext(async (_, { headers }) => {
  const token = await getToken('accessToken');

  return {
    headers: {
      ...headers,

      authorization: token ? `JWT ${token}` : '',
    },
  };
});

let isRefreshing = false;
let failedRequestsQueue: any[] = [];

const processQueue = async (error: any, token = null) => {
  failedRequestsQueue.forEach((prom) => (error ? prom.reject(error) : prom.resolve(token)));
  failedRequestsQueue = [];
  isRefreshing = false;
};

const refreshAccessToken = async () => {
  const accessToken = await getToken('accessToken');
  const refreshToken = await getToken('refreshToken');

  let r;
  console.log(isTelegram, accessToken, refreshToken, !!accessToken && !!refreshToken);
  if (!!accessToken && !!refreshToken) {
    console.log('test');
    r = await fetch(graphqlLink, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `JWT ${accessToken}`,
      },
      body: JSON.stringify({
        query: `mutation refreshTokens {refreshTokens(refreshToken: "${refreshToken}") {
                                            accessToken
                                            refreshToken
                                        }}`,
      }),
    });
  } else if (isTelegram) {
    const initData = webApp.initData;
    const token = generateBase64(initData);
    console.log(initData);
    console.log(token);
    r = await fetch(graphqlLink, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query: `mutation loginByTelegramToken {loginByTelegramToken(token: "${token}") {
                                            accessToken
                                            refreshToken
                                        }}`,
      }),
    });
  }

  console.log(r);

  return r?.json();
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, path }) => {
      console.log(`[GraphQL error]: Message: ${extensions?.message}, Path: ${path}`);
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
  if (
    graphQLErrors?.find(
      ({ extensions }) => extensions?.code === 'UNAUTHENTICATED' && operation.operationName !== 'loginByTelegramToken' && operation.operationName !== 'me',
    )
  ) {
    // @ts-ignore
    return new Observable(async (observer) => {
      // Кладем запрос в очередь отклоненных запросов, там он будет ждать решения по обновлению токена
      new Promise((resolve, reject) => {
        failedRequestsQueue.push({ resolve, reject });
      })
        .then(() => {
          // Если все ок, то идем дальше, пуская вперед остальные запросы;
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };
          forward(operation).subscribe(subscriber);
        })
        .catch(() => {
          // Refresh-токен тоже просрочен, редирект на авторизацию произведет первый запрос в очереди отклоненных
        });
      // Если данный запрос первый в очереди отклоненных, то есть до него никто не поставил isRefreshing
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          // Идем вручную на рефреш токена, ибо клиент Apollo испорчен старым токеном до момента обновления
          const data = await refreshAccessToken();
          console.log('data', data);
          // Если токен не получилось обновить, идем на авторизацию
          if (data.errors?.length) {
            throw new Error('Error refreshing token');
          }
          // Если все ок, обновляем токен
          const requestData = data.data.refreshTokens || data.data.loginByTelegramToken;
          const { accessToken, refreshToken } = requestData;
          const _cookies = [
            {
              name: 'accessToken',
              value: accessToken,
            },
            {
              name: 'refreshToken',
              value: refreshToken,
            },
          ];

          console.log(_cookies);

          for (const cookie of _cookies) {
            await setToken(cookie?.name, cookie?.value);
          }

          // Запускаем очередь отклоненных запросов с новым токеном
          await processQueue(null, accessToken);
        } catch (e) {
          console.log('error', e);
          await processQueue(e, null);
          // Аналогично ошибкам GQL, если не достучались до сервера вообще, идем на авторизацию

          for (const cookie of ['accessToken', 'refreshToken']) {
            await removeToken(cookie);
          }

          if (!isTelegram) window.location.href = `/signup?redirectUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`;
        }
      }
    });
  }
});

const client = new ApolloClient({
  link: from([errorLink, authMiddleware, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
