import { createBrowserRouter } from 'react-router-dom';
import React from 'react';
import Error404 from 'pages/Error404';
import SuccessPage from 'pages/SuccessPage';
import Payment from 'pages/Payment/Payment';
import ROUTES from 'constants/routes';
import CoursePage from 'pages/Course';
import BlockPage from 'pages/Block';
import Lesson from 'pages/Lesson';
import ExercisePage from 'pages/Lesson/ExercisePage';
import Landing from 'pages/Landing';
import HomeworkInfo from 'pages/Lesson/Homework/HomeworkInfo';
import HomeworkResult from 'pages/Lesson/Homework/HomeworkResult';
import CourseDescription from 'pages/Onboarding/CourseDescription';
import SignUp from 'pages/SignUp';
import CourseDashboard from 'pages/CourseDashboard';
import CardPreview from 'pages/CardPreview';
import SelectUserRole from 'pages/Onboarding/SelectUserRole';
import SelectGrade from 'pages/Onboarding/SelectGrade';
import SelectCourse from 'pages/Onboarding/SelectCourse';
import OnboardingOutlet from 'pages/Onboarding/OnboardingOutlet';
import Redirect from 'pages/Redirect';
import CourseLayout from './layouts/CourseLayout';

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <Error404 />,
    element: <Landing />,
  },
  {
    path: '/redirect',
    errorElement: <Error404 />,
    element: <Redirect />,
  },
  {
    path: ROUTES.ONBOARDING,
    errorElement: <Error404 />,
    element: <OnboardingOutlet />,
    children: [
      {
        index: true,
        element: <SelectUserRole />,
      },
      {
        path: ROUTES.SELECT_GRADE,
        children: [
          {
            index: true,
            element: <SelectGrade />,
          },
          {
            path: ':grade',
            children: [
              {
                index: true,
                element: <SelectCourse />,
              },
              {
                path: ':courseId',
                element: <CourseDescription />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    errorElement: <Error404 />,
    element: <CourseLayout />,
    children: [
      {
        path: ROUTES.COURSE,
        children: [
          {
            index: true,
            element: <CoursePage />,
          },
          {
            path: 'dashboard',
            element: <CourseDashboard />,
          },
          {
            path: ':blockId',
            children: [
              {
                index: true,
                element: <BlockPage />,
              },
              {
                path: ':lessonId',
                element: <Lesson />,
                children: [
                  {
                    path: ':exerciseId',
                    children: [
                      {
                        index: true,
                        element: <ExercisePage />,
                      },
                      {
                        path: ':elementId',
                        element: <ExercisePage />,
                      },
                      {
                        path: 'preview',
                        element: <HomeworkResult />,
                      },
                      {
                        path: 'result',
                        element: <HomeworkResult isResult />,
                      },
                      {
                        path: 'info',
                        element: <HomeworkInfo />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      // {
      //   path: ROUTES.QUIZ,
      //   element: (
      //     <QuizProvider>
      //       <QuizPage />
      //     </QuizProvider>
      //   )
      // },
      {
        path: `${ROUTES.PAYMENT}`,
        children: [
          {
            path: ':courseId',
            element: <Payment />,
          },
          {
            path: ':courseId/:blockId',
            element: <Payment />,
          },
        ],
      },
      {
        path: ROUTES.SUCCESS_PAYMENT,
        element: <SuccessPage />,
      },
      {
        path: ROUTES.SIGNUP,
        element: <SignUp />,
      },
      {
        path: 'card/:cardId',
        element: <CardPreview />,
      },
    ],
  },
]);

export default router;
