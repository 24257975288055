import { Components, formControlLabelClasses, outlinedInputClasses, Theme } from '@mui/material';
import { CourseSubjectColor } from 'types/common';

export default {
  MuiCssBaseline: {
    styleOverrides: (themeParam) => `
        body {
          background-color: ${themeParam.palette.background.default};
        }
      `,
  },
  MuiAvatar: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.text2,
        width: 70,
        height: 70,
      }),
    },
  },
  MuiChip: {
    variants: [
      {
        props: { size: 'large' },
        style: ({ theme }) => ({
          ...theme.typography.h3,
          padding: '2px 12px',
        }),
      },
    ],
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.baseShift[500],
        color: theme.palette.baseShift[100],
        borderRadius: 30,
        boxShadow: theme.shadows[theme.palette.mode === 'light' ? 1 : 4],
        '&.Mui-disabled': {
          backgroundColor: theme.palette.baseShift[500],
          color: theme.palette.baseShift[300],
          opacity: 1,
        },
      }),
      deleteIcon: {
        marginLeft: 6,
        marginRight: 0,
      },
      colorError: ({ theme }) => ({
        backgroundColor: theme.palette.baseShift[500],
        color: theme.palette.error.main,
      }),
      sizeSmall: ({ theme }) => ({
        ...theme.typography.text6,
        padding: '4px 8px',
      }),
      sizeMedium: ({ theme }) => ({
        ...theme.typography.h6,
        padding: '7px 12px',
        lineHeight: 'inherit',
      }),
      label: {
        padding: 0,
      },
    },
  },
  MuiLinearProgress: {
    defaultProps: {
      variant: 'determinate',
      size: 'small',
    },
    variants: [
      {
        props: { size: 'small' },
        style: {
          height: 8,
        },
      },
      {
        props: { size: 'medium' },
        style: {
          height: 12,
        },
      },
    ],
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        const subject = ownerState.subject as CourseSubjectColor;
        const { value, coloringType } = ownerState!;
        const subjectGradient = `linear-gradient(90deg, ${theme.palette[subject].main} ${value}%, #3C93EE 100%)`;
        return {
          borderRadius: 8,
          backgroundColor: theme.palette.base[theme.palette.mode === 'light' ? 400 : 900],
          '& .MuiLinearProgress-bar': {
            borderRadius: 8,
            background: coloringType === 'single' ? theme.palette[subject].main : subjectGradient,
          },
        };
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1,
        textTransform: 'initial',
        borderRadius: 30,
      },
      sizeSmall: ({ ownerState }) => ({
        ...(ownerState.startIcon || ownerState.endIcon ? { padding: '6px 12px' } : { padding: '10px 16px' }),
      }),
      sizeMedium: ({ ownerState }) => ({
        ...((ownerState.startIcon || ownerState.endIcon) && { padding: '12px 19px' }),
      }),
      sizeLarge: ({ ownerState }) => ({
        ...(ownerState.startIcon || ownerState.endIcon ? { padding: '12px 19px' } : { padding: '20px 16px' }),
      }),
      endIcon: {
        marginLeft: 10,
      },
      contained: ({ theme }) => {
        const isLightMode = theme.palette.mode === 'light';
        const { palette } = theme;
        return {
          padding: 16,
          color: palette.base[isLightMode ? 800 : 100],
        };
      },
      containedPrimary: ({ theme, ownerState }) => {
        const isLightMode = theme.palette.mode === 'light';
        const { palette, shadows } = theme;
        const darkBackgroundColor = ownerState.darkModeColor === 'dark' ? 700 : 600;
        return {
          backgroundColor: palette.base[isLightMode ? 200 : darkBackgroundColor],
          boxShadow: shadows[0],
          '&:hover': {
            boxShadow: shadows[0],
            backgroundColor: ownerState.subject ? palette[ownerState.subject].main : palette.baseShift[500],
            color: palette.base[100],
          },
          '&.Mui-disabled': {
            backgroundColor: palette.baseShift[400],
            color: palette.base[isLightMode ? 300 : 400],
          },
        };
      },
      containedSecondary: ({ theme, ownerState }) => {
        const isLightMode = theme.palette.mode === 'light';
        const { palette, shadows } = theme;
        return {
          backgroundColor: palette.baseShift[500],
          boxShadow: shadows[isLightMode ? 1 : 0],
          '&:hover': {
            backgroundColor: ownerState.subject ? palette[ownerState.subject].main : palette.baseShift[500],
            boxShadow: shadows[isLightMode ? 1 : 2],
            color: palette.base[100],
          },
          '&.Mui-disabled': {
            backgroundColor: palette.baseShift[500],
            color: palette.base[isLightMode ? 300 : 400],
          },
        };
      },
      text: ({ theme }) => ({
        color: theme.palette.base[400],
        textDecorationColor: theme.palette.base[400],
        '&:hover': {
          color: theme.palette.baseShift.text,
          textDecorationColor: theme.palette.baseShift.text,
          backgroundColor: 'transparent',
        },
        '&.Mui-disabled': {
          color: theme.palette.baseShift[300],
        },
      }),
    },
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: '20px',
        padding: 16,
        backgroundColor: theme.palette.baseShift[500],
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: '10px',
        [`&:not(.Mui-error):hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.base[300],
        },
        [`&:not(.Mui-error).Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.base[300],
        },
        [`&.Mui-error .${outlinedInputClasses.input}`]: {
          color: theme.palette.error.main,
          '&::placeholder, &::placeholder': {
            color: theme.palette.error.main,
          },
        },
        padding: '14px 12px',
      }),
      input: ({ theme }) => ({
        color: theme.palette.base[500],
        padding: 0,
        '&:focus': {
          borderColor: 'transparent',
          outline: 'none',
        },
        '&::placeholder, &::placeholder': {
          color: theme.palette.base[300],
          opacity: 1,
        },
      }),
      notchedOutline: ({ theme }) => ({
        border: '2px solid',
        borderColor: theme.palette.base[300],
        '&.Mui-error': {
          borderColor: theme.palette.error.main,
        },
      }),
      adornedEnd: ({ theme }) => ({
        '&.Mui-error .MuiInputAdornment-root': {
          color: theme.palette.error.main,
        },
        '.MuiInputAdornment-root': {
          color: theme.palette.base[500],
        },
      }),
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        color: theme.palette.base[300],
        padding: 8,
        '&.Mui-checked': {
          color: ownerState.subject ? theme.palette[ownerState.subject].main : theme.palette.base[300],
        },
        '&.Mui-disabled': {
          color: theme.palette.base[300],
        },
        '& .MuiSvgIcon-root': { width: 20, height: 20 },
      }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        alignItems: 'flex-start',
        '& .MuiCheckbox-root, & .MuiRadio-root': {
          marginTop: -6,
        },
        [`& .${formControlLabelClasses.label}`]: {
          ...theme.typography.text4,
        },
        [`& .${formControlLabelClasses.label}.Mui-disabled`]: {
          color: theme.palette.base[300],
        },
      }),
    },
  },
  MuiFormGroup: {
    styleOverrides: {
      root: {
        '& .MuiFormControlLabel-root': {
          marginBottom: 10,
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        color: theme.palette.base[300],
        padding: 8,
        '&.Mui-checked': {
          color: ownerState.subject ? theme.palette[ownerState.subject].main : theme.palette.base[300],
        },
        '&.Mui-disabled': {
          color: theme.palette.base[300],
        },
        '& .MuiSvgIcon-root': { width: 20, height: 20 },
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }) => ({
        background: theme.palette.baseShift[500],
        borderRadius: 20,
        boxShadow: theme.shadows[theme.palette.mode === 'light' ? 1 : 0],
        width: 353,
        padding: '24px 16px 16px',
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: 0,
        marginBottom: 24,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiIconButton: {
    variants: [
      {
        props: { variant: 'contained' },
        style: ({ theme }) => {
          const isLightMode = theme.palette.mode === 'light';
          const { palette, shadows } = theme;
          return {
            backgroundColor: palette.baseShift[500],
            boxShadow: shadows[isLightMode ? 3 : 4],
            color: theme.palette.baseShift.text,
            '&:hover': {
              backgroundColor: palette.baseShift[500],
            },
            '&.Mui-disabled': {
              backgroundColor: palette.baseShift[500],
              color: palette.base[isLightMode ? 300 : 400],
            },
          };
        },
      },
    ],
    styleOverrides: {
      sizeSmall: {
        height: 44,
        width: 44,
        '& > .MuiSvgIcon-root': {
          width: 32,
          height: 32,
        },
      },
      sizeLarge: {
        height: 48,
        width: 48,
        '& > .MuiSvgIcon-root': {
          width: 32,
          height: 32,
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.baseShift.text,
        '&[class*="text"]': {
          display: 'block',
        },
        '& ol': {
          listStyle: 'none',
          counterReset: 'customCounter',
          '& > li::before': {
            content: "counter( customCounter ) ') '",
            counterIncrement: 'customCounter',
          },
        },
        '& ol, & ul': {
          padding: 0,
        },
      }),
    },
    defaultProps: {
      variantMapping: {
        'h1.0': 'h1',
        'h1.1': 'h1',
        'h2.1': 'h2',
        'h3.1': 'h3',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: ({ theme }) => ({
        '&.MuiOutlinedInput-input': {
          ...theme.typography.text4,
          color: theme.palette.baseShift[100],
        },
      }),
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        ...theme.typography.text4,
        padding: '8px 12px',
        '&:hover, &.Mui-selected': {
          backgroundColor: theme.palette[ownerState.subject].main,
        },
      }),
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        '&.MuiMenu-list': {
          padding: 0,
        },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: ({ theme }) => ({
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        border: `2px solid ${theme.palette.base[300]}`,
        borderTop: 'none',
      }),
    },
  },
} as Components<Theme>;
