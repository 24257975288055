import { FormControl, Select, MenuItem, SelectChangeEvent, SxProps } from '@mui/material';
import { useController } from 'react-hook-form';
import { FC } from 'react';
import generateList from 'components/form/generateList';
import { CourseSubjectColor } from 'types/common';

type SelectProps = {
  name: string;
  list: any[];
  subject: CourseSubjectColor;
  elementPartId?: string;
  disabled?: boolean;
  sx?: SxProps;
};

const SelectControl: FC<SelectProps> = ({ name, list, subject, elementPartId, disabled, sx }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
  });
  return (
    <FormControl fullWidth error={!!error}>
      <Select
        sx={sx}
        disabled={disabled}
        value={field.value?.text || field.value}
        onChange={(event: SelectChangeEvent) => {
          if (elementPartId) field.onChange({ text: event.target.value, elementPartId });
          else field.onChange(event.target.value);
        }}
      >
        {list?.map((item, index) => {
          const { label, value }: { label: string; value: any } = typeof item === 'string' ? generateList(item, index) : item;
          return (
            <MenuItem value={value} subject={subject}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectControl;

SelectControl.defaultProps = {
  elementPartId: undefined,
  disabled: false,
  sx: undefined,
};
