import { Theme } from '@mui/material';
import { CourseSubject } from '@generated/graphql';

const getBackground = (theme: Theme, subject: CourseSubject | 'defaultColor' | undefined, backgroundType: 'background' | 'mainBackground') => {
  const isLightMode = theme.palette.mode === 'light';
  if (isLightMode) {
    if (subject) return theme.palette[subject][backgroundType];
    return theme.palette.background.default;
  }
  return theme.palette.base[900];
};

export default getBackground;
