import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { ExerciseType, LessonQuery, useLessonQuery } from '@generated/graphql';
import CircularLoading from 'components/CircularLoading';
import { Typography } from '@mui/material';
import { ArrayElement, IOutletContext } from 'types/common';

export enum TestState {
  preview = 'preview',
  result = 'result',
  info = 'info',
  previewAnswer = 'previewAnswer',
  resultAnswer = 'resultAnswer',
}

type LessonPageParams = {
  lessonId: string;
  courseId: string;
  exerciseId: string;
  elementId?: string;
};

type LessonType = LessonQuery['lesson'] | undefined;
type ExerciseArrayType = LessonQuery['lesson']['exercises'];
export type LessonExerciseType = ArrayElement<ExerciseArrayType>;

const Lesson = () => {
  const params = useParams<LessonPageParams>();

  const outletContext = useOutletContext<IOutletContext>();

  const { data, loading, error, refetch } = useLessonQuery({ variables: { lessonId: params.lessonId! } });

  const lesson: LessonType = data?.lesson;
  const exercises: ExerciseArrayType = lesson?.exercises;
  const homework = exercises?.find((exercise: LessonExerciseType) => exercise.type === ExerciseType.Homework && exercise.isAvailable);

  if (loading) return <CircularLoading />;
  if (error?.message) return <Typography>{error.message}</Typography>;

  return <Outlet context={{ ...outletContext, homework, refetchLesson: refetch, lesson }} />;
};

export default Lesson;
