import { Chip, Stack } from '@mui/material';
import SUBJECT from 'constants/subject';
import { FC, useContext } from 'react';
import { AuthContext } from 'context/authContext';
import { useInitData } from '@vkruglikov/react-telegram-web-app';
import { CourseSubject } from '@generated/graphql';

const CourseHeader: FC<{ subject: CourseSubject | undefined }> = ({ subject }) => {
  const { me } = useContext(AuthContext);
  const [initDataUnsafe] = useInitData();
  const telegramUsername = initDataUnsafe?.user?.username;

  const username = telegramUsername ? `@${telegramUsername}` : me?.firstName;

  return (
    <Stack direction='row' justifyContent='space-between'>
      {subject && <Chip label={SUBJECT[subject].name} />}
      {username && <Chip label={username} />}
    </Stack>
  );
};

export default CourseHeader;
