import { Card, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { AccessTime } from '@mui/icons-material';
import { format } from 'date-fns';
import { IRadioButtonProps } from 'components/form/RadioButtonControl';
import AnswerComponent from 'components/ElementsGenerator/elements/AnswerComponent';
import { CourseSubjectColor } from 'types/common';
import Text from 'components/ElementsGenerator/elements/Text';
import { QuestionType } from '@generated/graphql';
import { UseFormReturn } from 'react-hook-form';
import Image from './Image';

export type TaskContainerProps = {
  placeholder?: string;
  totalTaskCount: number | undefined;
  currentTask: number;
  time?: Date;
  subject: CourseSubjectColor;
  title?: string;
  questionImg?: string;
  questionType: QuestionType;
  name: string;
  elementParts?: IRadioButtonProps[] | string[];
  form: UseFormReturn;
  description: string;
  correctAnswers?: Array<{ isCorrect?: boolean | null; content: string; correctAnswer?: string | null; order: number }> | undefined | null;
};

const TaskContainer: FC<TaskContainerProps> = (props) => {
  const { totalTaskCount, currentTask, time, subject, description, title, questionImg, questionType, ...rest } = props;
  return (
    <Card sx={{ borderRadius: 4 }}>
      <Stack direction='row' justifyContent='space-between' mb={1.5}>
        <Typography sx={(theme) => ({ color: theme.palette[subject].main })} variant='text2'>
          {`${currentTask}/${totalTaskCount}`}
        </Typography>
        {time && (
          <Stack direction='row' alignItems='center'>
            <AccessTime
              sx={(theme) => ({
                color: theme.palette[subject].main,
                width: 24,
                height: 24,
                mr: 0.5,
              })}
            />
            <Typography variant='text3'>{format(time, 'HH:mm:ss')}</Typography>
          </Stack>
        )}
      </Stack>
      <Stack spacing={2}>
        {title && <Text content={title} />}
        {questionImg && <Image questionImg={questionImg} />}
        {description && <Text content={description} />}
        <AnswerComponent subject={subject} questionType={questionType} {...rest} />
        {rest.correctAnswers?.length ? (
          <Typography variant='text4' sx={{ whiteSpace: 'pre-wrap' }}>
            {`Правильный ответ: ${
              questionType === QuestionType.TextAnswers
                ? rest.correctAnswers[0]?.correctAnswer
                : rest.correctAnswers
                    .map((answer: any) => (questionType === QuestionType.Matching ? `\n${answer.content} - ${answer.correctAnswer}` : answer.order))
                    .join(', ')
            }`}
          </Typography>
        ) : null}
      </Stack>
    </Card>
  );
};
export default TaskContainer;
